$(document).ready(function () {

 
    var crp = $("meta[name=SectionType]").prop("content") == "Industrial member report";


    if (crp)
    {
        
        var nocrp = "<a href='mailto:crp@twi.co.uk?subject=Industrial member report export control request&body=Request for report: [" + window.location.href + "]' class='btn twi_btn_orange export-control'>This report may be subject to export control, please contact <u>crp@twi.co.uk</u></a>";

        var downloadIcon = "<i class='fas fa-file-download' style='position: absolute; top: 50%; right: 1em; font-size: 1.35em; margin-top: -0.5em;'></i>";
    
        var membershipCTA = "<span style='padding: 1.5em 0; display: block;' class='member-only-message'><i class='fas fa-lock' style='margin-right: 0.5em;'></i> This content is only available to Industrial Members, <a href='/who-we-are/industrial-membership' title='Industrial Membership'>Find out how to become a Member of TWI.</a></span>";


        $('a[href*="/pdfs/industrial-member-reports"]')
        .closest('.pel-inner, .asset.attachment, .crp-report-link')
        .hide()
        .after(membershipCTA)
        .after(nocrp);

    }
 
    var contensisUserName = encodeURIComponent($.getCookie("ContensisCMSUserName"));

    if (contensisUserName != "undefined") {

        $.ajax({
        type: "GET",
        url: "https://cms-twi.cloud.contensis.com/REST/Contensis/Security/GetUserInfo",
        headers: {"ContensisCMSUserName": contensisUserName},
        contentType: "application/json; charset=utf-8",
        success: function(data) {
            var user = $.parseJSON(data);

            $(".quick-links__button_login, .quick-links__button_register").hide();

            $(".quick-links__button_logout").show();

            $.each(user.GroupCollection, function(i, obj)
            {
                if (obj.GroupName === "@Key Account Portal" || obj.GroupName === "Key account managers" || obj.GroupName === "Developers")
                {
                    $(".quick-links__button_portal").show();

                }

                var crp = $("meta[name=SectionType]").prop("content") == "Industrial member report";

                if ((obj.GroupName === "@TWI Staff")) {
                    if (crp)
                    {

                        var nocrpStaff = "<a href='mailto:crp@twi.co.uk?subject=Industrial member report export control request&body=Request for report: [" + window.location.href + "]' class='btn twi_btn_orange export-control'><strong>TWI STAFF ACCESS ONLY</strong>.<br /> This report may be subject to export control, please contact <u>crp@twi.co.uk</u></a>";

                        $('.export-control, .member-only-message').remove();

                        $('a[href*="/pdfs/industrial-member-reports"]')
                        .addClass("btn download-file")
                        .prepend(downloadIcon)
                        .closest('.pel-inner, .asset.attachment, .crp-report-link')
                        .show()
                        .after(nocrpStaff);

                        
                    }

                }


            });


        },
        failure: function(errMsg) {
            // eslint-disable-next-line no-console
            console.log(errMsg);
        }
    });
    }

    //
    //Modify all mailto:contactus@twi.co.uk links to include a subject containing the page the link is being clicked on.
    //
    $('a[href^="mailto:contactus@twi.co.uk"]').each(function(){
        if (this.href.indexOf("?") <= 0)
            this.href=this.href + "?subject=Website enquiry from ["+window.location.href +"]";
    });

    //
    //Registration form
    //
    //$("#UserRegistration_UserRegistrationPanel").find("label").addClass("visuallyhidden");
    $("#UserRegistration_UserRegistrationPanel .sys_firstname").find("input").attr("placeholder", "First Name");
    $(".sys_lastname").find("input").attr("placeholder", "Last Name");
    $(".sys_jobrole").find("input").attr("placeholder", "Company Name");
    $(".sys_jobrole").hide();
    
    $(".sys_addressdetails").find("legend").hide();
    $(".sys_address1").find("input").attr("placeholder", "Company Name");
    $(".sys_address1").find("label").hide();
    $(".sys_addresspostcode").find("input").attr("placeholder", "Company Postcode");
    $(".sys_addresspostcode").find("label").hide();
    $(".sys_addresscountry").find("input").attr("placeholder", "Country");
    $(".sys_addresscountry").find("label").hide();
    $(".sys_address2").hide();
    $(".sys_addresstown").hide();
    $(".sys_addresscounty").hide();
    $(".sys_fax").hide();
    //$(".sys_fax").find("input").attr("placeholder", "Company Postcode");
    $(".sys_telephone").find("input").attr("placeholder", "Telephone");
    $(".sys_mobile").hide();
    //find("input").attr("placeholder", "Mobile");
    $(".sys_email").find("input").attr("placeholder", "Company Email");
    $(".sys_emailconfirm").find("input").attr("placeholder", "Confirm Company Email");
    $("#AccountInformation p:contains(Please ensure you have entered your email correctly as this will be used as your login name.)").remove();
    $("#AccountInformation p:contains(Please choose your password)").css("display","none");
    $(".sys_password").find("input").attr("placeholder", "Please choose your password");
    $(".sys_passwordconfirm").find("input").attr("placeholder", "Please confirm your password");
    //Rename Term & Conditions
    $(".termsandconditions legend").text("I Agree to TWI's Terms and Conditions");
    $(".sys_email").before($(".sys_jobrole")).before($(".sys_fax"));
    $(".sys_accountinfo")
        .find("legend span")
        .html("<b>Please complete the following fields so we can validate your membership status</b><br />"
            + "A verification email will be sent to this address, please click on the link in the email" 
        );

    //       
    // Subscribe popup 
    //



    $(".subscribePopup .close").click(function(){
        // eslint-disable-next-line no-undef
        Cookies.set('hideSubscribePopup', 'value', { expires: 60, path: '/' });
        $(".subscribePopup").fadeOut(1500);
    });

    $(".subscribePopup a.btn").click(function(){
        // eslint-disable-next-line no-undef
        Cookies.set('hideSubscribePopup', 'value', { expires: 60, path: '/' });
        $(".subscribePopup").fadeOut(1500);
    });           

    // Subscribe popup - Show if they dont have the hideSubscribePopup cookie
    
    if (getCookie('hideSubscribePopup')) {
        // The 'hideSubscribePopup' cookie exists
        $(".subscribePopup").css("display","none");
      } else {
        // The 'hideSubscribePopup' cookie doesn't exist
        $(".subscribePopup").delay(6000).fadeIn(2000);
      }
 

    //
    // when unsubscribe selected de-delect all othe checkboxes
    //
    $('#UserRegistration_UserRegistrationPanel.newsletters input:checkbox').change(function () {
    if ($(this).is('input#RemoveMe')) {
        var checkboxes = $('#UserRegistration_UserRegistrationPanel.newsletters').find(':checkbox').not($(this));
        checkboxes.prop('checked', false);
    }
    });
    


    //
    // when unsubscribe selected de-delect all othe checkboxes
    //
    $('#UserRegistration_UserRegistrationPanel.newsletters input:checkbox').change(function () {
        if ($(this).is('input#RemoveMe')) {
            var checkboxes = $('#UserRegistration_UserRegistrationPanel.newsletters').find(':checkbox').not($(this));
            checkboxes.prop('checked', false);
        }
        else {
            $('#UserRegistration_UserRegistrationPanel.newsletters').find('input#RemoveMe').prop('checked', false);
        }
    });

    //
    // make Newsletter preferences form full width
    //
    $("#UserRegistration_UserRegistrationPanel.newsletters").parents(".large-8").removeClass("large-8 large-pull-2").addClass("large-10").next().remove();
    
    /* Sitemap accordian */

    //
    // Add accordian icon "X"
    //
    $(".sitemap .sys_folder > a").parent().prepend("<div class='accordian-icon'></div>");
    $(".sitemap .sys_folder > span").parent().prepend("<div class='accordian-icon'></div>");

    //
    // Click function 
    //
    $(".sitemap .accordian-icon").siblings("ul").each(function(){
        $(this).addClass("hideList");
        $(this).siblings(".accordian-icon").click(function(){
            $(this).toggleClass("closeIcon");
            $(this).siblings("ul").toggleClass("hideList");
        });
    });


    // Remove enlarge button and image from the DOM
    $(".asset-inline .asset-width .enlarge").remove();

    $(function() {
        $('.lazy').Lazy({
            effect: 'fadeIn',
            // beforeLoad: function(element){
            //     // eslint-disable-next-line no-console
            //     console.log('image "' + element.data('src') + '" is about to be loaded');
            // },
            // afterLoad: function(element){
            //     // eslint-disable-next-line no-console
            //     console.log('image "' + element.data('src') + '" was loaded successfully');
            // },
            onError: function(element) {
                // eslint-disable-next-line no-console
                console.log('error loading ' + element.data('src'));
            }
        });
    });

});

function getCookie(name) {
    var cookies = document.cookie.split(';');
    var trimmedCookies = [];
    for (var i = 0; i < cookies.length; i++) {
      trimmedCookies.push(cookies[i].trim());
    }
    for (var j = 0; j < trimmedCookies.length; j++) {
      if (trimmedCookies[j].indexOf(name + '=') === 0) {
        return true;  // The cookie with the given name exists
      }
    }
    return false; // The cookie with the given name is not found
  }